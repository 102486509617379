import styles from '@/styles/components/common/Testimonial.module.scss'
import Image from 'next/image'
import { ImageLoaderProps } from 'next/image'
import parse from 'html-react-parser'

const loader = ({ src, width, quality }: ImageLoaderProps) => {
  return `${process.env.NEXT_PUBLIC_SITE}${src}?w=${width}&q=${quality || 75}`
}

const noop = () => {
  return
}

interface Props {
  name: string,
  text: string,
  avatarUrl?: string,
  className?: string,
  caption?: string,
  height?: number,
  initials?: string,
  initialsColor?: string,
  link?: string,
  linkText?: string,
  url?: string,
  onClick?(name: string): void
}

export const TestimonialBig: React.FC<Props> = ({ name, text, avatarUrl, className = '', height, caption = '', link = '', linkText = '', initials = '', initialsColor = '#be55f0', url = '', onClick = noop }: Props) => {
  const rednerContent = () => {
    return (
      <>
        <div className='flex'>
          { avatarUrl && <div className={`${styles.testimonial_big__avatar} ${styles.testimonial__avatar}`}>
            <Image
              loader={loader}
              src={avatarUrl}
              width="44"
              height="44"
              layout="responsive"
              objectFit="contain"
              alt="testimonial avatar"
            />
          </div> }
          {initials && !avatarUrl && <div className={`${styles.testimonial_big__avatar} ${styles.testimonial__avatar}`}>
            <span className={`${styles.testimonial_initials} font--accent font-bold`} style={{backgroundColor: initialsColor}}>{initials}</span>
          </div>}
          <div className={`${styles.testimonial_row}`}>
            <h6 className={styles.testimonial__name}>{name}</h6>
            {caption && <p className={`${styles.testimonial__review} color-gray`}>{caption}</p>}
            {!caption && <p className={styles.testimonial__review}>1 review</p>}
          </div>
        </div>
        <div className={`${link && styles.testimonial_calc_height} ${styles.testimonial_big_line}`}>
          <div className='flex mb-6'>
            <div className={`mr-[16px] ${styles.testimonial__stars}`}>
              <span className={styles.testimonial__star}>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
                </svg>
              </span>
              <span className={styles.testimonial__star}>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
                </svg>
              </span>
              <span className={styles.testimonial__star}>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
                </svg>
              </span>
              <span className={styles.testimonial__star}>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
                </svg>
              </span>
              <span className={styles.testimonial__star}>
                <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
                </svg>
              </span>
            </div>
            <span className={styles.testimonial__verified}>
              <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.8125 6C11.8125 9.21016 9.21016 11.8125 6 11.8125C2.78984 11.8125 0.1875 9.21016 0.1875 6C0.1875 2.78984 2.78984 0.1875 6 0.1875C9.21016 0.1875 11.8125 2.78984 11.8125 6ZM5.32767 9.07767L9.64017 4.76517C9.78661 4.61873 9.78661 4.38129 9.64017 4.23485L9.10985 3.70453C8.96341 3.55807 8.72597 3.55807 8.57951 3.70453L5.0625 7.22152L3.42049 5.57951C3.27405 5.43307 3.03661 5.43307 2.89015 5.57951L2.35983 6.10983C2.21339 6.25627 2.21339 6.49371 2.35983 6.64015L4.79733 9.07765C4.94379 9.22411 5.18121 9.22411 5.32767 9.07767Z"></path>
              </svg>
              Verified
            </span>
          </div>
          <div className={`flex flex-col justify-between ${link && styles.testimonial_calc_height_sec}`}>
            <p className={`${styles.testimonial__content} !mt-0`}>{parse(text)}</p>
            {link && <a className={styles.testimonial__link_sec} href={link} target="_blank" rel="noreferrer">
              {linkText ? linkText : 'Read the full review'}
            </a>}
          </div>
        </div>
      </>
    )
  }

  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer" className={`${styles.testimonial} ${styles.testimonial_hover_anim} ${styles.testimonial_big} ${className}`} style={{height: height || 'auto'}}>
        {rednerContent()}
      </a>
    )
  }

  return (
    <div className={`${styles.testimonial} ${styles.testimonial_big} ${className}`} style={{height: height || 'auto'}}>
      {rednerContent()}
    </div>
  )
}
