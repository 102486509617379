interface Props {
  style?: React.CSSProperties,
  color?: string,
  responsive?: boolean
}

export default function Inc({ style, responsive = false, color = '' }: Props) {
  return (
    <i className="icon" style={style}>
      <svg width={`${responsive ? '100%' : '117'}`} height={`${responsive ? '100%' : '40'}`} viewBox="0 0 117 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M73.4932 30.3793C72.765 28.703 72.3773 26.7869 72.3773 24.6312C72.3773 14.0468 81.7918 9.3836 91.3645 9.3836H91.9989C94.9081 9.3836 106.543 10.1172 107.76 21.8017L96.177 23.0593C96.0714 19.8628 95.3838 16.5097 91.47 16.5097C86.4458 16.5097 86.0754 20.6489 86.0754 24.5788V24.6837C86.0754 28.6133 86.4987 32.7528 91.4703 32.7528C94.0619 32.7528 96.3887 31.2855 96.3887 28.1417H107.918L107.865 29.1374C105.962 38.4116 97.4465 39.8786 91.3645 39.8786C84.1136 39.8786 76.9126 37.2038 73.8943 31.276V38.961H52.7953V30.3793H56.7814V21.4288C56.7814 18.7439 54.8149 17.5328 52.317 17.5328C49.1281 17.5328 46.7365 19.8494 46.7365 23.0084V30.4317H50.8289V39.0135H29.2514V30.4317H33.1313V17.5328H29.2517V8.95102H46.2582V14.9004C46.5773 14.637 46.8429 14.3213 47.0556 14.0579C50.1382 10.5307 53.6988 9.26706 58.3756 9.26706C64.9659 9.26706 70.2805 11.8995 70.2805 19.1124V30.4317V30.3793H73.4932ZM0.428711 9.21589V0.0837402H27.7346V9.21589H22.5785V30.5594H27.7346V39.0135H0.428711V30.5594H5.53192V9.21615L0.428711 9.21589ZM111.819 39.2295C109.186 39.2295 107.051 37.0992 107.051 34.4714C107.051 31.8434 109.186 29.7134 111.819 29.7134C114.452 29.7134 116.587 31.8437 116.587 34.4717C116.587 37.0994 114.452 39.2295 111.819 39.2295Z" fill={color || 'white'}/>
      </svg>
    </i>
  )
}
