import styles from '@/styles/components/carousel/EndlessCarousel.module.scss'
import { createRef, useRef, RefObject, useState, useEffect } from 'react'

interface Props {
  animationSpeed?: number,
  slides: Slide[],
  delayStart?: number,
  className?: string
}

interface Slide {
  content: JSX.Element
}

export const EndlessCarousel: React.FC<Props> = ({
  animationSpeed = 2000,
  slides,
  delayStart = 1000,
  className = ''
}) => {
  const slideRefs = useRef<RefObject<HTMLDivElement>[]>([])
  const intervalId = useRef<ReturnType<typeof setTimeout>>()
  const [activeIndex, setActiveIndex] = useState(0)
  const [offsetState, setOffsetState] = useState(0)
  const [removeTransition, setRemoveTransition] = useState(true)
  const [inTransitionState, setInTransitionState] = useState(false)
  const [started, setStarted] = useState(false)
  const [duplicatedSlides, setDuplicatedSlides] = useState<Slide[]>([])

  const calculateIndex = (newIndex: number) => {
    let index = newIndex

    if (isLastSlide(index - 1)) {
      index = 0
    }

    return index
  }

  const getSlideCount = () => {
    return slides.length
  }

  const isLastSlide = (index: number) => {
    return getSlideCount() - 1 === index
  }

  const getSlideWidth = (index: number) => {
    return slideRefs.current[index].current?.offsetWidth || 0
  }

  const setInitialOffset = () => {
    setOffsetState(getSlideWidth(getSlideCount() - 1)) // Last slide
  }

  const goNext = () => {
    if (inTransitionState) return
    setInTransitionState(true)

    const indexToSet = calculateIndex(activeIndex + 1)

    setOffsetState((offset) => {
      const width = getSlideWidth(activeIndex)
      const newWidth = offset + width

      return newWidth
    })
    setActiveIndex(indexToSet)
  }

  const calculatePosition = () => {
    return `translate3d(-${offsetState}px, 0, 0)`
  }

  const setupLoop = () => {
    intervalId.current = setInterval(() => {
      goNext()
    }, animationSpeed)
  }

  const removeLoop = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
    }
  }

  const handleTransitionEnd = () => {
    if (activeIndex === 0) {
      setRemoveTransition(true)
      setInitialOffset()

      setTimeout(() => {
        setRemoveTransition(false)
      }, 30)
    }

    setInTransitionState(false)
  }

  const createSlideRefs = () => {
    if (slideRefs.current.length > 0) return

    Array(slides.length * 2).fill(null).map((_, i) => {
      slideRefs.current[i] = slideRefs.current[i] || createRef<HTMLDivElement>()
    })
  }

  const duplicateSlides = () => {
    setDuplicatedSlides([...slides, ...slides])
  }

  useEffect(() => {
    if (!started) return

    setupLoop()

    return () => {
      removeLoop()
    }
  }, [activeIndex, inTransitionState, started])

  useEffect(() => {
    duplicateSlides()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setInitialOffset()

      setTimeout(() => {
        setRemoveTransition(false)
        setStarted(true)
      }, 30)
    }, delayStart)
  }, [duplicatedSlides.length])

  createSlideRefs()

  return (
    <div className={`${styles.endless_carousel_container} ${className}`}>
      <div
        onClick={goNext}
        className={`${styles.endless_carousel} ${removeTransition ? '' : styles.transition}`}
        style={{
          transform: calculatePosition()
        }}
        onTransitionEnd={handleTransitionEnd}
      >
        {duplicatedSlides.length > 0 && <div className={styles.endless_carousel_item}>
          {duplicatedSlides[duplicatedSlides.length - 1].content}
        </div>}
        {duplicatedSlides.map((slide, i) =>
          <div key={i} ref={slideRefs.current[i]} className={styles.endless_carousel_item}>
            {slide.content}
          </div>
        )}
      </div>
    </div>
  )
}
