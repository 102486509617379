interface Props {
  style?: React.CSSProperties,
  color?: string,
  responsive?: boolean
}

export default function Entrepreneur({ style, responsive = false, color = '' }: Props) {
  return (
    <i className="icon" style={style}>
      <svg width={`${responsive ? '100%' : '259'}`} height={`${responsive ? '100%' : '51'}`} viewBox="0 0 259 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.379518 34.6191L3.65282 34.0953V3.76191L0 3.28572L0.189757 0H23.0554L22.4862 9.19047L17.932 9.04762L17.6473 3.28572H10.5789V16.9524H13.6625L14.3741 13.8096L17.5525 13.9048V23.3333H14.3741L13.6151 20.0953H10.5315V34.5714H17.6473L18.3116 28.2857L23.1029 28.4286L23.4824 38H0.237195L0.379518 34.6191Z" fill={color || 'white'}/>
        <path d="M25.6173 38.0477L25.7595 35.2381L28.3212 34.7143V15.5714L25.3325 15.2857V12.4286L32.828 11.3333L33.3973 12.9524V14.0476C35.1999 12.1429 37.3821 11 40.1811 11C44.2608 11 47.297 13.4762 47.297 18.3809V34.6666L50.0009 35.1905L49.8587 38H40.94V19.8095C40.94 17.1905 39.9913 15.7143 37.6193 15.7143C36.8128 15.7143 36.2436 15.8572 35.6269 16.0952C35.2474 16.4286 34.8678 16.9047 34.5358 17.3333V34.7619L37.2398 35.2857L37.05 38.0952H25.6173V38.0477Z" fill={color || 'white'}/>
        <path d="M168.931 38.0477L169.026 35.2381L171.587 34.7143V15.5714L168.599 15.2857V12.4286L176.095 11.3333L176.664 12.9524V14.0476C178.467 12.1429 180.648 11 183.447 11C187.527 11 190.563 13.4762 190.563 18.3809V34.6666L193.267 35.1905L193.125 38H184.254V19.8095C184.254 17.1905 183.305 15.7143 180.933 15.7143C180.126 15.7143 179.557 15.8572 178.941 16.0952C178.562 16.4286 178.181 16.9047 177.849 17.3333V34.7619L180.553 35.2857L180.364 38.0952H168.931V38.0477Z" fill={color || 'white'}/>
        <path d="M64.1378 37.5239C63.0941 37.9523 61.244 38.4286 58.8247 38.4286C55.6462 38.4286 52.7049 37 52.7049 32.3333V15.5715L49.6688 15.0952L49.8112 11.7619L53.2268 11.6191L54.6975 6.52381L59.0618 6.33334V11.8095H63.7583L63.4262 15.1429L59.1093 15.6191V32.381C59.1093 33.7619 59.8682 34.6666 61.2915 34.6666H63.8532L64.1378 37.5239Z" fill={color || 'white'}/>
        <path d="M241.703 38.0477L241.798 35.2857L244.218 34.7143V15.5715L241.371 15.2857V12.4286L249.15 11.3333L249.673 13.1905V13.8096C251.712 12.2857 254.464 11.2857 257.785 11.2857L258.923 12.2857L258.117 19.5238L253.847 19.381L253.42 15.5238C252.425 15.6667 251.475 15.9524 250.669 16.5238V34.7619L253.373 35.3334L253.183 38.0952H241.703V38.0477Z" fill={color || 'white'}/>
        <path d="M129.983 38.0477L130.126 35.2857L132.545 34.7143V15.5715L129.699 15.2857V12.4286L137.479 11.3333L138.001 13.1905V13.8096C140.041 12.2857 142.792 11.2857 146.113 11.2857L147.251 12.2857L146.35 19.5238L142.08 19.381L141.653 15.5238C140.657 15.6667 139.708 15.9524 138.902 16.5238V34.7619L141.606 35.3334L141.416 38.0952H129.983V38.0477Z" fill={color || 'white'}/>
        <path d="M66.0354 38.0477L66.1776 35.2857L68.597 34.7143V15.5715L65.7507 15.2857V12.4286L73.5307 11.3333L74.0526 13.1905V13.8096C76.0925 12.2857 78.844 11.2857 82.1172 11.2857L83.2558 12.2857L82.4493 19.5238L78.1798 19.381L77.7529 15.5238C76.7566 15.6667 75.8079 15.9524 75.0014 16.5238V34.7619L77.7053 35.3334L77.5157 38.0952H66.0354V38.0477Z" fill={color || 'white'}/>
        <path d="M90.6089 26.381C90.6089 30.9524 92.6013 34.4762 96.5388 34.4762C98.5787 34.4762 100.192 34.0476 102.089 33.1429L103.18 35.6667C101.235 37.2381 98.3889 38.4286 94.831 38.4286C87.9049 38.4286 83.9199 32.4286 83.9199 24.7143C83.9199 18.1428 86.8138 10.9524 94.7361 10.9524C101.662 10.9524 104.271 17.381 104.271 24.5238L103.607 26.2381L90.6089 26.381ZM90.514 23.3333L98.4837 22.9524C98.3415 19.7619 97.6773 14.7143 94.5463 14.7143C93.7873 14.7143 93.2654 14.8571 92.3167 15.3334C91.0358 17.5239 90.514 21.4762 90.514 23.3333Z" fill={color || 'white'}/>
        <path d="M200.858 26.381C200.858 30.9524 202.851 34.4762 206.788 34.4762C208.828 34.4762 210.44 34.0476 212.338 33.1429L213.429 35.6667C211.484 37.2381 208.638 38.4286 205.079 38.4286C198.154 38.4286 194.168 32.4286 194.168 24.7143C194.168 18.1428 197.062 10.9524 204.985 10.9524C211.911 10.9524 214.52 17.381 214.52 24.5238L213.855 26.2381L200.858 26.381ZM200.763 23.3333L208.733 22.9524C208.591 19.7619 207.926 14.7143 204.795 14.7143C204.036 14.7143 203.515 14.8571 202.565 15.3334C201.285 17.5239 200.763 21.4762 200.763 23.3333Z" fill={color || 'white'}/>
        <path d="M154.557 26.381C154.557 30.9524 156.55 34.4762 160.487 34.4762C162.527 34.4762 164.139 34.0476 166.037 33.1429L167.128 35.6667C165.184 37.2381 162.337 38.4286 158.779 38.4286C151.853 38.4286 147.867 32.4286 147.867 24.7143C147.867 18.1428 150.761 10.9524 158.684 10.9524C165.61 10.9524 168.22 17.381 168.22 24.5238L167.556 26.2381L154.557 26.381ZM154.462 23.3333L162.432 22.9524C162.29 19.7619 161.625 14.7143 158.494 14.7143C157.735 14.7143 157.214 14.8571 156.265 15.3334C154.984 17.5239 154.415 21.4762 154.462 23.3333Z" fill={color || 'white'}/>
        <path d="M232.452 38.0477L231.788 35.7619C230.032 37.4286 228.325 38.4286 225.669 38.4286C221.588 38.4286 217.936 36.381 217.936 30.7143V14.9048L214.947 14.6191V12.0952L223.581 11.0952L224.245 13.381V29.9524C224.245 32.8572 225.431 34.381 227.756 34.381C228.42 34.381 229.274 34.2857 230.032 34C230.46 33.5714 230.887 33.0476 231.219 32.5238V14.9048L228.135 14.6191V12.0952L236.864 11.0952L237.528 13.381V34.7143L240.232 35.2381L239.9 38.0477H232.452Z" fill={color || 'white'}/>
        <path d="M117.222 34.6191C115.847 34.6191 114.756 34.381 113.902 33.9048V17.0953C114.281 16.5238 114.708 16 115.135 15.5238C115.941 15.2381 116.653 15.1429 117.507 15.1429C121.065 15.1429 121.682 18.762 121.682 25.2381C121.682 31.4762 120.733 33.1905 120.496 33.9048C119.499 34.381 118.456 34.6191 117.222 34.6191ZM127.849 24.0953C127.849 16.0476 125.476 11 119.547 11C115.609 11 113.759 13.2858 113 14.2381V13.3333L112.336 11.0476L104.604 12.1429V14.9048L107.592 15.2857V47.7619L105.03 48.2857L104.888 51H116.321L116.511 48.2857L113.807 47.7619V37.4762C114.898 38.0952 116.416 38.4762 118.076 38.4762C127.754 38.4762 127.849 28.6191 127.849 24.0953Z" fill={color || 'white'}/>
      </svg>
    </i>
  )
}
